import * as React from "react"
import PropTypes from "prop-types"
import logo from "../../public/static/images/inventa_logo.png"
import "./header.css"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"

const MyHeader = ({ siteTitle, menuLinks }) => (
  <header>
    <Navbar bg="light" variant="light" expand="md" id="navbar">
      <Container fluid="lg">
        <Navbar.Brand href="/">
          <img className="brand-logo" src={logo} alt="Inventa Energy" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-end nav" style={{width: `100%`}}>

            {menuLinks.map(link => {
              if (link.hasOwnProperty("subMenu")) {

                return (
                  <NavDropdown id="header-link" className="menu-dropdown" title={link.name}>
                    {link.subMenu.map(subLink => {
                      
                      if (subLink.hasOwnProperty("subMenu")) {
                        return (

                          <NavDropdown id="sub-link" className="menu-dropdown sub-menu-dropdown" drop="end" title={subLink.name}>
                            {subLink.subMenu.map(subSubMenu => {
                              return <Nav.Link id="sub-link" activeClassName="active" exact href={subSubMenu.link}>{subSubMenu.name}</Nav.Link>
                            })}
                          </NavDropdown>

                        )
                      } else {
                        return <Nav.Link id="sub-link" activeClassName="active" exact href={subLink.link}>{subLink.name}</Nav.Link>
                      }
                    })}
                  </NavDropdown>
                )
              } else {
                return <Nav.Link id="header-link" activeClassName="active" exact href={link.link} >{link.name}</Nav.Link>
              }
            }
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
)

MyHeader.propTypes = {
  siteTitle: PropTypes.string,
}

MyHeader.defaultProps = {
  siteTitle: ``,
}

export default MyHeader