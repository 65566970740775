import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import MyHeader from "./header"
import MyFooter from "./footer";
// import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          socialLinks {
            name
            link
          }
          contactDetails {
            address
            email
            website
            mobile
          }
        }
      }
    }
  `)

  const menuLinks = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'About Us',
      link: '/about-us'
    },
    {
      name: 'Products',
      link: '/products',
      subMenu: [
        {
          name: 'Anti Smog Gun',
          link: '/anti-smog-gun',
          subMenu: [
            {
              name: 'Truck Mounted',
              link: '/truck-mounted'
            },
            {
              name: 'Semi Automatic',
              link: '/semi-automatic'
            },
          ]
        },
        {
          name: 'Auto Collector',
          link: '/auto-collector'
        },
        {
          name: 'Refuse Compactor',
          link: '/refuse-compactor'
        },
        {
          name: 'Dumper Placer',
          link: '/dumper-placer'
        },
        {
          name: 'Hook Loader',
          link: '/hook-loader'
        },
        {
          name: 'Portable Compactor',
          link: '/portable-compactor'
        },
        {
          name: 'Road & Street Sweeper',
          link: '/road-street-sweeper'
        },
        {
          name: 'Super Sucker',
          link: '/super-sucker'
        },
        {
          name: 'Suction Cum Jetting',
          link: '/suction-cum-jetting'
        },
        {
          name: 'Sewer Suction',
          link: '/sewer-suction'
        },
        {
          name: 'Nala Cleaning',
          link: '/nala-cleaning'
        },
        {
          name: 'Desilting',
          link: '/desilting'
        },
        {
          name: 'Sky Lift',
          link: '/sky-lift'
        },
        {
          name: 'Toilets',
          link: '/toilets',
          subMenu: [
            {
              name: 'Mobile Toilet Bio',
              link: '/mobile-toilet-bio'
            },
            {
              name: 'Portable Toilet',
              link: '/portable-toilet'
            },
            {
              name: 'Multi Seats',
              link: '/multi-seats'
            },
          ]
        },
        {
          name: 'Water Tanker',
          link: '/water-tanker'
        },
        {
          name: 'Fogging Machine',
          link: '/fogging-machine'
        },
        {
          name: 'Dustbins',
          link: '/dustbins'
        },
      ]
    },
    {
      name: 'Gallery',
      link: '/gallery'
    },
    {
      name: 'Contact Us',
      link: '/contact-us'
    }
  ]

  return (
    <>
      <MyHeader siteTitle={data.site.siteMetadata.title || `Title`} menuLinks={menuLinks} />
      <div style={{ margin: `0 auto`, }}>
        <main>{children}</main>
        <MyFooter socialLinks={data.site.siteMetadata.socialLinks} contactDetails={data.site.siteMetadata.contactDetails} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
