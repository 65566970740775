import React from "react"
import "./styles/footer.css"
import { IoIosArrowForward } from "react-icons/io"
import {
  BsFillGeoAltFill,
  BsEnvelopeOpenFill,
  BsGlobe,
  BsFillTelephoneFill,
} from "react-icons/bs"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

function MyFooter({ socialLinks, contactDetails }) {
  return (
    <footer className="page-footer font-small blue pt-4">
      <Container className="text-md-left" fluid="md">
        <div className="row">
          <div id="footer-col1" className="col-md-6 mt-md-0 mt-3">
            <p id="heading" className="text-uppercase">
              Contact
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <BsFillGeoAltFill id="icon" />
                  </td>

                  <td>{contactDetails.address}</td>
                </tr>
                <tr>
                  <td>
                    <BsEnvelopeOpenFill id="icon" />
                  </td>

                  <td>{contactDetails.email}</td>
                </tr>
                <tr>
                  <td>
                    <BsGlobe id="icon" />
                  </td>

                  <td>{contactDetails.website}</td>
                </tr>
                <tr>
                  <td>
                    <BsFillTelephoneFill id="icon" />
                  </td>

                  <td>{contactDetails.mobile}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* <hr className="clearfix w-100 d-md-none pt-3" /> */}

          <div id="footer-col2" className="col-md-3 mb-md-0 mb-3">
            <p id="heading" className="text-uppercase">
              Links
            </p>

            <ul className="list-unstyled">
              <li>
                <Link to="/">
                  <IoIosArrowForward id="arrow-icon" />
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about-us">
                  <IoIosArrowForward id="arrow-icon" />
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/gallery">
                  <IoIosArrowForward id="arrow-icon" />
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/contact-us">
                  <IoIosArrowForward id="arrow-icon" />
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div id="footer-col3" className="col-md-3 mb-md-0 mb-3">
            <p id="heading" className="text-uppercase">
              Let's Connect
            </p>

            <ul className="list-unstyled">
              {socialLinks.map(link => (
                <li>
                  <a target="_blank" rel="noopener" href={link.link}>
                    <IoIosArrowForward id="arrow-icon" />
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>

      <div className="footer-copyright text-center py-3">
        © 2022 Copyright&nbsp;:&nbsp;
        <a target="_blank" rel="noopener" href="https://mysofttechnology.com/">
          MySoftTechnology.com
        </a>
      </div>
    </footer>
  )
}

export default MyFooter
